import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import {
  IContentBuilderFieldColor,
  IContentBuilderFieldImage,
} from '@core/models/content-builder-fields.model';
import { map, Observable } from 'rxjs';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { IDistributionMode } from '@core/models/shop.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import { ParametersUtils } from '@shared/utils/parameters/parameters.utils';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { Router } from '@angular/router';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { ContentBuilderFieldColors } from '@shared/interfaces/content-builder.interface';
import { SettingsParametersState } from '@store/settings';
import { SettingsStoreDispatcher } from '@common/dispatchers/settings.dispatcher';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { CartKeys } from '@config/keys/cart.keys';

@Component({
  selector: 'app-shop-hero-template-block',
  templateUrl: './shop-hero-template-block.component.html',
  styles: [
    `
      @media (min-width: 1024px) and (min-height: 820px) {
        .lg-and-min-height-820-hover-upper-container {
          @apply absolute;
          @apply bottom-0;
        }
      }
    `,
  ],
})
export class ShopHeroTemplateBlockComponent implements OnInit {
  @Input() displayNavigation = true;
  @Input() navigationLogo?: IContentBuilderFieldImage;
  @Input() accentColor?: ContentBuilderFieldColors;

  @Input() redirectUrl?: string;

  @Input() heroImage?: IContentBuilderFieldImage;
  @Input() heroLogo?: IContentBuilderFieldImage;
  @Input() heroBaseline?: string;
  @Input() heroBaselineColor?: IContentBuilderFieldColor;
  @Input() heroDistributionButtonColor?: IContentBuilderFieldColor;
  @Input() heroDistributionButtonBackgroundColor?: IContentBuilderFieldColor;

  @Input() heroContextualHeading?: string;
  @Input() heroContextualHeadingColor?: IContentBuilderFieldColor;
  @Input() heroContextualContent?: string;

  @Input() displayCarousel = false;
  @Input() carouselCardColor?: IContentBuilderFieldColor;

  @Input() cards?: [];

  destroyRef = inject(DestroyRef);

  availableDistributionModes$?: Observable<ISimpleItem[]>;
  settingsParameters$? = this.settingsSelector.selectParameters;

  constructor(
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly settingsSelector: SettingsStoreSelector,
    private readonly router: Router,
    private readonly routerHelper: RouterHelperService,
    private readonly settingsStoreDispatcher: SettingsStoreDispatcher,
    private readonly cartStoreDispatcher: CartStoreDispatcher,
  ) {}

  ngOnInit(): void {
    this.availableDistributionModes$ =
      this.marketplaceSelector.selectMarketplaceDistributionModes.pipe(
        map((distributions: IDistributionMode[]) =>
          ParametersUtils.mapDistributionModes(distributions),
        ),
      );
  }

  openParametersModal(distributionMode: string): void {
    const selectedDistributionModeParameters: Partial<SettingsParametersState> = {
      is_distribution_mode_defined: true,
      distribution_mode: distributionMode,
      last_update: new Date(),
    };

    this.settingsStoreDispatcher.updateParameters(selectedDistributionModeParameters);
    // update existing parameters, ! It may cause side effects, like if a slot was already used, maybe the slot is still in use but not appropriate
    this.cartStoreDispatcher.updateActive({
      [CartKeys.Type]: distributionMode,
    });

    this.redirectToAnotherPage();
  }

  redirectToAnotherPage(): void {
    if (this.redirectUrl) {
      this.redirectUrl.startsWith('http') || this.redirectUrl.startsWith('https')
        ? window.open(this.redirectUrl)
        : this.router.navigate([this.routerHelper.translateRoute(this.redirectUrl)]);
    }
  }
}
