import { find } from 'lodash';
import { from, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { DoodCartModel } from '@core/models/cart.model';
import { DoodShopModel } from '@core/models/shop.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import { IValidateDeliveryAddress } from '@core/models/order.model';
import { DoodMarketplaceModel } from '@core/models/marketplace.model';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import { OrderTypeValues } from '@config/values/order.values';
import { ParametersWantedAtTypeValues } from '@config/values/parameters.values';

import { CartKeys } from '@config/keys/cart.keys';
import { DistributionModeKeys, ShopKeys } from '@config/keys/shop.keys';

import { DateUtils } from '@shared/utils/date/date.utils';
import { LocationUtils } from '@shared/utils/location/location.utils';
import { DaySlotsUtils } from '@shared/utils/day-slots/day-slots.utils';
import { ITimeSlot, TimeSlotsUtils } from '@shared/utils/time-slots/time-slots.utils';
import { IOrderTypeCapabilities } from '@config/order-types-capabilities.config';
import { AlertModalComponent } from '@shared/modals/alert-modal/alert-modal.component';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';
import { DoodOnSiteLocationSourceValues } from '@store/on-site-location/on-site-location.enum';

import { CartStoreRefiner } from '@common/refiners/cart.refiner';
import { ShopStoreSelector } from '@common/selectors/shop.selector';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { BasketStoreSelector } from '@common/selectors/basket.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { DeliveryStoreSelector } from '@common/selectors/delivery.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';
import { OnSiteLocationStoreSelector } from '@common/selectors/on-site-location.selector';
import { OnSiteLocationStoreDispatcher } from '@common/dispatchers/on-site-location.dispatcher';

import { CartService } from '@core/services/cart/cart.service';
import { ShopsService } from '@core/services/shops/shops.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { BasketService } from '@core/services/basket/basket.service';
import { NativeService } from '@core/services/native/native.service';
import { OrdersService } from '@core/services/orders/orders.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { DeliveryAddressService } from '@core/services/delivery-address/delivery-address.service';
import { OnSiteLocationsService } from '@core/services/on-site-locations/on-site-locations.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';
import { ErrorService } from '@core/services/error-service/error.service';
import { EntitiesApiService } from '@core/services/api/entities/entities-api.service';
import { EntitiesService } from '@core/services/entities/entities.service';
import { RouterHelperService } from '@core/services/router-helper/router-helper.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '@core/services/app/analytics.service';
import { HttpParams } from '@angular/common/http';
import { ApiParametersKeys } from '@config/keys/parameters.keys';
import { DoodEntityModel } from '@core/models/entity.model';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

@Component({
  selector: 'app-cart-parameters-modal',
  templateUrl: './cart-parameters-modal.component.html',
})
export class CartParametersModalComponent
  extends ModalScrollBlockBase
  implements OnInit, OnDestroy
{
  private _destroyerRef = new Subject<boolean>();

  static handle = 'cart-parameters-modal';
  modalZIndex: Observable<number> = this.modalSelector
    .selectModal(CartParametersModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  @Input() displayAsapForLaterPicker = true;

  activeShop$ = this.shopSelector.selectActive;
  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;

  selectedOrderTypeCapabilities?: IOrderTypeCapabilities;
  availableDistributionModes?: ISimpleItem[];
  isDeliveryAddressValid = false;
  deliveryAddressError?: string;
  isCheckDeliveryAddressInProgress = false;
  isSaving = false;
  message?: string;
  selectedDistributionMode?: string;
  selectedLocation?: DoodDeliveryAddressModel;
  selectedWantedAtType?: ParametersWantedAtTypeValues;
  selectedWantedAtDate: Date | null = null;
  selectedWantedAtTime: Date | null = null;
  wantedAtDateSlots?: Date[];
  wantedAtTimeSlots?: ITimeSlot[];
  onSiteLocationName?: string;
  selectedOnSiteLocationId?: string;
  private previousSelectedWantedAtTime: Date | null = null;
  isNotched = false;
  existingCartMessage?: string;
  orderErrorMessages: string[] = [];
  deliverableShop?: DoodEntityModel;
  deliveryComment?: string | null;
  instructions$ = this.cartSelector.selectActive.pipe(
    map(cart => cart?.delivery_address?.instructions),
  );
  distributionMode = OrderTypeValues;

  public readonly userIsLoggedIn$ = this.authSelector.selectUserIsLoggedIn;

  constructor(
    private cartRefiner: CartStoreRefiner,
    private cartSelector: CartStoreSelector,
    private shopSelector: ShopStoreSelector,
    private modalSelector: ModalStoreSelector,
    private readonly cartService: CartService,
    private basketSelector: BasketStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly shopsService: ShopsService,
    private readonly modalsService: ModalsService,
    private readonly ordersService: OrdersService,
    private readonly basketService: BasketService,
    private settingsSelector: SettingsStoreSelector,
    private deliverySelector: DeliveryStoreSelector,
    private marketplaceSelector: MarketplaceStoreSelector,
    private readonly orderTypeService: OrderTypeService,
    private readonly translateService: TranslateService,
    private onSiteLocationSelector: OnSiteLocationStoreSelector,
    private onSiteLocationDispatcher: OnSiteLocationStoreDispatcher,
    private readonly onSiteLocationsService: OnSiteLocationsService,
    private readonly deliveryAddressService: DeliveryAddressService,
    private readonly shopSearchParametersService: ShopSearchParametersService,
    private readonly errorService: ErrorService,
    private readonly entitiesApiService: EntitiesApiService,
    private readonly entitiesService: EntitiesService,
    private readonly routerHelper: RouterHelperService,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly authSelector: AuthStoreSelector,
  ) {
    super();
  }

  ngOnInit(): void {
    this.updateLocalFromCart();
    this.updateMessage();
    this.updateWantedAtDateSlots();
    this.loadDeliveryComment();

    from(NativeService.isNotched())
      .pipe(take(1))
      .subscribe(_isNotched => {
        this.isNotched = _isNotched;
      });

    this.loadOnSiteLocation();

    if (this.selectedDistributionMode === OrderTypeValues.Delivery) {
      this.cartRefiner.selectActiveCartDeliveryAddress
        .pipe(takeUntil(this._destroyerRef))
        .subscribe(address => {
          this.checkParametersDeliveryAddress(address);
        });
    }

    this.getWarningMessage();
    this.errorService
      .getErrorMessage$()
      .pipe(
        takeUntil(this._destroyerRef),
        tap(messages => (this.orderErrorMessages = messages)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyerRef.next(true);
    this._destroyerRef.complete();
    super.ngOnDestroy();
  }

  changeDistributionMode(distributionMode: string): void {
    this.selectedDistributionMode = distributionMode;
    this.updateSelectedOrderTypeCapabilities();
    this.updateMessage();
    this.placeChanged(this.selectedLocation);
    this.loadOnSiteLocation(distributionMode);
    this.updateWantedAtDateSlots();
    this.updateWantedAtTimeSlots();

    if (this.selectedDistributionMode === OrderTypeValues.Delivery) {
      this.cartRefiner.selectActiveCartDeliveryAddress
        .pipe(takeUntil(this._destroyerRef))
        .subscribe(address => {
          this.checkParametersDeliveryAddress(address);
        });
    }
  }

  // TODO: WTF is this ?
  placeChanged(addressValue?: Partial<DoodDeliveryAddressModel>): void {
    let address = addressValue as DoodDeliveryAddressModel;
    this.isDeliveryAddressValid = false;
    this.deliveryAddressError = undefined;
    this.deliverableShop = undefined;
    this.deliveryComment = null;

    if (
      !this.selectedOrderTypeCapabilities ||
      !this.selectedOrderTypeCapabilities.isDeliveryAddressMandatory
    ) {
      return;
    }

    if (!address) {
      const { is_default_location, location } = this.settingsSelector.parameters;
      const parametersLocation = is_default_location ? undefined : location;
      if (!parametersLocation) {
        return;
      }
      address = this.deliveryAddressService.placeResultToDeliveryAddress(
        parametersLocation,
      ) as DoodDeliveryAddressModel;
    }

    if (!this.cartService.isAddressPreciseEnough(address)) {
      this.deliveryAddressError = this.translateService.instant('parameters.add-address');
      return;
    }

    this.isCheckDeliveryAddressInProgress = true;

    this.instructions$
      .pipe(
        take(1),
        tap(instructions => {
          this.deliveryComment = instructions;
        }),
      )
      .subscribe();

    const mappedLocation = this.ordersService.mapIDeliveryAddress(address);
    this.ordersService
      .checkAddressIsValid$(this.selectedDistributionMode, mappedLocation)
      .pipe(
        take(1),
        takeUntil(this._destroyerRef),
        tap(isValid => {
          this.isDeliveryAddressValid = isValid;
          this.isCheckDeliveryAddressInProgress = false;
          if (isValid) {
            this.selectedLocation = address;
            this.deliveryAddressError = undefined;
            this.deliverableShop = undefined;

            if (this.deliveryComment !== address.instructions && address.instructions !== null) {
              this.deliveryComment = address.instructions;
            }
          }
        }),
        catchError(result => {
          this.manageDeliveryAddressError(result, address);
          return of(null);
        }),
      )
      .subscribe();
  }

  submit(): void {
    if (!this.selectedDistributionMode) {
      return;
    }

    let cart = this.cartSelector.active;
    if (!cart) {
      this.cartService.createNewCart(null, this.selectedDistributionMode);
      // TODO: Check if dispatch done before this line
      cart = this.cartSelector.active;
    }

    if (!cart) {
      console.error('Unable to create cart');
      return;
    }

    this.onSiteLocationDispatcher.updateSettings({
      id: this.selectedOnSiteLocationId,
    });

    let mappedLocation: IValidateDeliveryAddress | undefined;
    if (this.selectedLocation) {
      mappedLocation = this.ordersService.mapIDeliveryAddress(this.selectedLocation);
    } else if (this.cartSelector.active?.delivery_address) {
      mappedLocation = this.cartSelector.active?.delivery_address;
    }

    if (mappedLocation && this.deliveryComment) {
      mappedLocation.instructions = this.deliveryComment;
    }

    if (
      this.selectedOrderTypeCapabilities &&
      this.selectedOrderTypeCapabilities.isDeliveryAddressMandatory &&
      mappedLocation
    ) {
      const isAddressPreciseEnough = this.cartService.isAddressPreciseEnough({
        city: mappedLocation.city,
        street: mappedLocation.street_line_1,
        country: mappedLocation.country ?? '',
      });
      if (!isAddressPreciseEnough) {
        this.deliveryAddressError = this.translateService.instant('parameters.add-address');
        return;
      }
    }
    const newCart: Partial<DoodCartModel> = {
      [CartKeys.Type]: this.selectedDistributionMode,
      [CartKeys.DeliveryAddress]: mappedLocation,
      [CartKeys.WantedAt]: this.selectedWantedAtTime,
      [CartKeys.OnSiteLocationId]: this.selectedOnSiteLocationId,
      ...(this.selectedOnSiteLocationId && {
        [CartKeys.OnSiteLocationName]: this.onSiteLocationSelector.locations.find(
          ({ id }) => id === this.selectedOnSiteLocationId,
        )?.name,
      }),
      [CartKeys.IsUserSelectedDistributionMode]: true,
    };

    this.isSaving = true;
    this.cartDispatcher.updateActive(newCart);
    this.ordersService.removeOrderErrors();

    const isBasketExist = !!this.basketSelector.basket.id;
    if (isBasketExist) {
      this.basketService.updateBasket$().pipe(take(1)).subscribe();
    }

    this.ordersService.checkCartIsValid$().pipe(take(1)).subscribe();

    this.shopSearchParametersService.set({
      wanted_at: this.selectedWantedAtTime,
    });

    const shop = this.shopSelector.active;
    const shopOrderTypeMismatch =
      shop && shop[ShopKeys.LoadedOrderType] !== newCart?.[CartKeys.Type];
    const cartTypeChanged = newCart?.[CartKeys.Type] !== cart?.[CartKeys.Type];
    const wantedAtChanged =
      newCart?.[CartKeys.WantedAt]?.toISOString() !== cart?.[CartKeys.WantedAt]?.toISOString();

    if (shopOrderTypeMismatch || cartTypeChanged || wantedAtChanged) {
      this.reloadActiveShop$()
        .pipe(
          take(1),
          tap(() => {
            this.modalsService.close(CartParametersModalComponent.handle);
            this.isSaving = false;
          }),
        )
        .subscribe();
    } else {
      this.modalsService.close(CartParametersModalComponent.handle);
      this.isSaving = false;
    }
  }

  private checkParametersDeliveryAddress(
    cartDeliveryAddress: IValidateDeliveryAddress | undefined,
  ): void {
    if (this.isSaving) {
      return;
    }

    const { is_default_location, location } = this.settingsSelector.parameters;

    const deliveryAddress = this.deliverySelector.active;
    const parametersLocation = is_default_location ? undefined : (location ?? undefined);

    if (cartDeliveryAddress?.city || deliveryAddress) {
      const address = cartDeliveryAddress?.city
        ? cartDeliveryAddress
        : deliveryAddress
          ? LocationUtils.mapINewDeliveryAddressToOdDeliveryAddress(
              deliveryAddress as DoodDeliveryAddressModel,
            )
          : this.ordersService.placeResultToDeliveryAddress(parametersLocation);

      this.isCheckDeliveryAddressInProgress = true;

      this.ordersService
        .checkAddressIsValid$(this.selectedDistributionMode, address)
        .pipe(
          take(1),
          catchError(error => {
            this.manageDeliveryAddressError(error, deliveryAddress);
            return of(null);
          }),
          tap(isValid => {
            if (isValid) {
              this.isDeliveryAddressValid = isValid;
              this.deliveryAddressError = undefined;
              this.deliverableShop = undefined;
              this.isCheckDeliveryAddressInProgress = false;
            }
          }),
        )
        .subscribe();
      return;
    }

    if (!deliveryAddress && parametersLocation) {
      const mappedLocation =
        this.deliveryAddressService.placeResultToDeliveryAddress(parametersLocation);
      const mappedLocation2 = this.ordersService.mapIDeliveryAddress(mappedLocation);

      if (mappedLocation.city || mappedLocation.point?.coordinates?.length) {
        this.isCheckDeliveryAddressInProgress = true;
        this.ordersService
          .checkAddressIsValid$(this.selectedDistributionMode, mappedLocation2)
          .pipe(
            take(1),
            takeUntil(this._destroyerRef),
            tap(isValid => {
              this.isDeliveryAddressValid = isValid;
              this.isCheckDeliveryAddressInProgress = false;
            }),
            catchError(result => {
              this.manageDeliveryAddressError(result);
              return of(null);
            }),
          )
          .subscribe();
      }
    }

    if (!deliveryAddress && !parametersLocation) {
      this.isDeliveryAddressValid = false;
    }
  }

  private manageDeliveryAddressError(result: any, address?: DoodDeliveryAddressModel | null): void {
    let error = result?.error?.violations?.[0]?.code;

    switch (error) {
      case 'DELIVERY_NO_DELIVERY_ZONES_CONFIGURED':
      case 'DELIVERY_OUTSIDE_DELIVERY_ZONE':
        this.deliveryAddressError = this.translateService.instant(
          'errors.delivery-address-outside-delivery-zone',
        );
        if (address) {
          this.checkDeliveryAddressOnOtherShop(address);
        }
        break;
      case 'INVALID_ADDRESS_FORMAT':
      case 'DELIVERY_NO_DELIVERY_ADDRESS':
      case 'DELIVERY_INVALID_DELIVERY_ADDRESS_FORMAT':
        this.deliveryAddressError = this.translateService.instant(
          'errors.delivery-address-invalid',
        );
        break;
      default:
        this.deliveryAddressError = this.translateService.instant('errors.unknown-error', {
          errorCode: error,
        });
    }

    this.isDeliveryAddressValid = false;
    this.isCheckDeliveryAddressInProgress = false;
  }

  private updateLocalFromCart(): void {
    let cart = this.cartSelector.active;
    const marketplace = this.marketplaceSelector.marketplace;

    if (!cart) {
      const firstDistributionMode = marketplace.distribution_modes
        .sort((a, b) => a.priority - b.priority)
        .filter(mode => !!mode.enabled)[0];
      this.cartService.createNewCart(null, firstDistributionMode.type);
      cart = this.cartSelector.active;
    }

    if (!cart) {
      console.error('Unable to create cart');
      return;
    }
    this.getShop(cart.shop)
      .pipe(
        takeUntil(this._destroyerRef),
        map(shop => {
          this.onSiteLocationName = cart?.[CartKeys.OnSiteLocationName];
          this.selectedOnSiteLocationId = cart?.[CartKeys.OnSiteLocationId];
          // todo check if already selected in settings & update settings accordingly?
          this.selectedDistributionMode = cart?.[CartKeys.Type];

          if (cart?.[CartKeys.DeliveryAddress]) {
            this.selectedLocation = LocationUtils.mapIValidatedDeliveryAddressToIDeliveryAddress(
              cart[CartKeys.DeliveryAddress] as IValidateDeliveryAddress,
            ) as DoodDeliveryAddressModel;
          }
          this.loadAvailableDistributionModes(marketplace, shop);
          this.updateSelectedOrderTypeCapabilities();

          if (this.userCanMakeSelection() === false) {
            this.submit();
          }

          if (this.selectedLocation?.street) {
            this.placeChanged(this.selectedLocation);
          }

          this.initSelectedWantedAt(cart?.[CartKeys.WantedAt]);
        }),
      )
      .subscribe();
  }

  private loadAvailableDistributionModes(
    marketplace: DoodMarketplaceModel,
    shop?: DoodShopModel,
  ): void {
    this.cartService
      .getCartEntityDistributionModes$(marketplace, shop?.[ShopKeys.Id])
      .pipe(
        take(1),
        tap(availableDistributionModes => {
          const allowedDistributionModeHandles = availableDistributionModes.map(item => item.value);
          if (!allowedDistributionModeHandles.length) {
            this.displayNoDistributionModeErrorModal();
          }

          if (
            !this.selectedDistributionMode ||
            !allowedDistributionModeHandles.includes(this.selectedDistributionMode)
          ) {
            this.changeDistributionMode(availableDistributionModes[0]?.value);
          }

          this.availableDistributionModes = availableDistributionModes;
        }),
      )
      .subscribe();
  }

  private userCanMakeSelection(): boolean {
    if (this.availableDistributionModes && this.availableDistributionModes.length > 1) {
      return true;
    }

    if (this.selectedOrderTypeCapabilities?.preorderingAllowed) {
      return true;
    }

    if (this.selectedOrderTypeCapabilities?.isDeliveryAddressMandatory) {
      return true;
    }

    if (this.selectedOrderTypeCapabilities?.isOnSiteLocationMandatory) {
      return true;
    }

    return false;
  }

  private displayNoDistributionModeErrorModal(): void {
    this.modalsService.open(AlertModalComponent.handle);
    this.modalsService.setData(AlertModalComponent.handle, {
      message: this.translateService.instant('shop-page.service-not-available'),
    });
  }

  private updateSelectedOrderTypeCapabilities(): void {
    this.selectedOrderTypeCapabilities = this.orderTypeService.getCapabilities(
      this.selectedDistributionMode,
    );
  }

  private reloadActiveShop$(): Observable<DoodShopModel | null> {
    const shopSlug = this.shopSelector.active?.slug;
    if (shopSlug) {
      return this.shopsService.updateShopDistributionMode$(shopSlug);
    }

    return of(null);
  }

  onWantedAtTypeChanged(wantedAtType: ParametersWantedAtTypeValues): void {
    this.selectedWantedAtType = wantedAtType;
    if (this.selectedWantedAtType === ParametersWantedAtTypeValues.Asap) {
      this.selectedWantedAtDate = null;
      this.selectedWantedAtTime = null;
    }
    this.updateWantedAtDateSlots();
    this.updateWantedAtTimeSlots();
  }

  onWantedAtDateChanged(wantedAtDate: Date | null): void {
    this.selectedWantedAtDate = wantedAtDate;
    this.previousSelectedWantedAtTime = this.selectedWantedAtTime;
    this.selectedWantedAtTime = null;
    this.wantedAtTimeSlots = undefined;
    this.updateWantedAtTimeSlots();
  }

  onWantedAtTimeChanged(wantedAtTime: Date | null): void {
    this.selectedWantedAtTime = wantedAtTime;
  }

  private updateWantedAtDateSlots(): void {
    const preOrderingDelays = this.orderTypeService.getPreOrderDelays(
      this.selectedDistributionMode,
    );

    const minDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(preOrderingDelays[DistributionModeKeys.PreorderingMinDelay], 'seconds')
      .startOf('day');
    const maxDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(preOrderingDelays[DistributionModeKeys.PreorderingMaxDelay], 'seconds')
      .startOf('day');

    const today = DateUtils.dayjsInMarketplaceTimezone().startOf('day');
    const minDays = minDate.diff(today, 'days');
    const maxDays = maxDate.diff(today, 'days');
    this.wantedAtDateSlots = DaySlotsUtils.generateDaySlots({
      interval: 1,
      minDays: minDays,
      maxDays: maxDays,
    });

    if (!this.selectedWantedAtDate) {
      this.selectedWantedAtDate = this.wantedAtDateSlots[0];
    }
    this.selectFirstWantedAtDateIfUndefined();
  }

  private selectFirstWantedAtDateIfUndefined(): void {
    if (
      !this.selectedWantedAtDate &&
      this.wantedAtDateSlots !== undefined &&
      this.wantedAtDateSlots.length > 0
    ) {
      this.selectedWantedAtDate = this.wantedAtDateSlots[0];
      this.updateWantedAtTimeSlots();
    }
  }

  private updateWantedAtTimeSlots(): void {
    this.wantedAtTimeSlots = undefined;
    const cart = this.cartSelector.active;
    if (this.selectedWantedAtType === ParametersWantedAtTypeValues.Asap) {
      return;
    }

    if (!this.selectedOrderTypeCapabilities?.preorderingAllowed) {
      return;
    }

    this.ordersService
      .getOrderSlots$(cart, this.selectedWantedAtDate, this.selectedDistributionMode)
      .pipe(
        takeUntil(this._destroyerRef),
        take(1),
        tap(slots => {
          this.wantedAtTimeSlots = TimeSlotsUtils.generateGivenTimeSlots(slots);
          if (!this.wantedAtTimeSlots.length) {
            this.selectedWantedAtTime = null;
            return;
          }

          this.selectedWantedAtTime = new Date(this.wantedAtTimeSlots[0].start.format());

          const firstSlotIsSameDay = this.wantedAtTimeSlots[0].start.isSame(
            this.selectedWantedAtDate,
            'day',
          );
          if (!firstSlotIsSameDay) {
            this.selectedWantedAtDate = new Date(
              this.wantedAtTimeSlots[0].start.startOf('day').format(),
            );
          }

          if (this.previousSelectedWantedAtTime) {
            const selectedSlot = find(
              this.wantedAtTimeSlots,
              slot =>
                slot.start.format('HH:mm:ss') ===
                DateUtils.dayjsInMarketplaceTimezone(this.previousSelectedWantedAtTime).format(
                  'HH:mm:ss',
                ),
            );
            if (selectedSlot) {
              this.selectedWantedAtTime = new Date(selectedSlot.start.format());
            }
          }
        }),
      )
      .subscribe();
  }

  private initSelectedWantedAt(wantedAt?: Date | null): void {
    if (!wantedAt && this.displayAsapForLaterPicker) {
      this.selectedWantedAtType = ParametersWantedAtTypeValues.Asap;
    } else {
      this.selectedWantedAtType = ParametersWantedAtTypeValues.Later;
    }

    if (wantedAt) {
      this.selectedWantedAtDate = wantedAt;
      this.previousSelectedWantedAtTime = wantedAt;
      this.selectedWantedAtTime = wantedAt;
    }

    this.updateWantedAtTimeSlots();
  }

  private updateMessage(): void {
    const currentDistributionMode = this.availableDistributionModes?.find(
      distributionMode => distributionMode.value === this.selectedDistributionMode,
    );
    this.message = undefined;
    if (currentDistributionMode) {
      this.message = currentDistributionMode.message;
    }
  }

  onOnSiteLocationChanged(id: string): void {
    this.onSiteLocationDispatcher.updateSettings({
      id,
      lastLocationId: id,
      source: DoodOnSiteLocationSourceValues.fromUser,
    });
    this.onSiteLocationDispatcher.updateActive(id);
    this.selectedOnSiteLocationId = id;
  }

  private loadOnSiteLocation(distributionMode?: string): void {
    this.cartSelector.selectActive
      .pipe(
        take(1),
        switchMap(cart => {
          const shopId = cart?.shop;
          if (shopId) {
            return this.onSiteLocationsService.loadOnSiteLocations$(shopId);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private getWarningMessage(): void {
    this.modalsService
      .getData(CartParametersModalComponent.handle)
      .pipe(
        take(1),
        tap(value => {
          const text = value?.data?.text;
          this.existingCartMessage = text ? String(text) : undefined;
        }),
      )
      .subscribe();
  }

  private getShop(shopId?: string): Observable<DoodShopModel | undefined> {
    if (shopId) {
      return this.shopSelector.selectShop(shopId).pipe(
        switchMap(_shop => {
          if (!_shop) return this.shopsService.loadShopById$(shopId);
          return of(_shop);
        }),
      );
    }
    return of(undefined);
  }

  private checkDeliveryAddressOnOtherShop(location: DoodDeliveryAddressModel) {
    let params = new HttpParams();
    params = params.append('order_type', OrderTypeValues.Delivery);
    params = params.append(ApiParametersKeys.Latitude, location.point.coordinates[1] as number);
    params = params.append(ApiParametersKeys.Longitude, location.point.coordinates[0] as number);
    params = params.append(ApiParametersKeys.MaxDistance, 15000);
    const marketplaceId = this.marketplaceSelector.marketplace.id;

    this.entitiesApiService
      .get$(marketplaceId, params)
      .subscribe(entities => (this.deliverableShop = entities[0] ?? null));
  }

  goToShop(deliverableShop: DoodEntityModel) {
    this.analyticsService.trackEvent('choose_another_shop', {
      cause: 'not-deliverable-address',
      new_shop_id: deliverableShop?.id,
      new_shop_name: deliverableShop?.name,
    });
    const shopUrl = this.entitiesService.getUrl(deliverableShop);
    this.modalsService.close(CartParametersModalComponent.handle);
    this.router.navigate([this.routerHelper.translateRoute(shopUrl)]);
  }

  private loadDeliveryComment() {
    this.cartSelector.selectActive
      .pipe(
        take(1),
        tap(cart => {
          this.deliveryComment = cart?.delivery_address?.instructions;
        }),
      )
      .subscribe();
  }

  deliveryCommentChanged(comment: string) {
    this.deliveryComment = comment;
  }

  forceType(data: any): any {
    return data as any;
  }
}
