import { find } from 'lodash';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { Component, Input, OnInit } from '@angular/core';

import { DistributionModeKeys } from '@config/keys/shop.keys';

import { OrderTypeValues } from '@config/values/order.values';
import { BASE_CUSTOM_SWITCH_VALUES } from '@config/values/parameters.values';

import { IDistributionMode } from '@core/models/shop.model';
import { PlaceResult } from '@core/models/google-maps.model';
import { ISimpleItem } from '@core/models/simple-item.model';
import { DoodWantedAtType } from '@store/settings/settings.model';
import { ILocationParameter } from '@core/models/parameters.model';
import { DoodDeliveryAddressModel } from '@core/models/delivery-address.model';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { SettingsStoreSelector } from '@common/selectors/settings.selector';
import { MarketplaceStoreSelector } from '@common/selectors/marketplace.selector';

import { DateUtils } from '@shared/utils/date/date.utils';
import { LocationUtils } from '@shared/utils/location/location.utils';
import { DaySlotsUtils } from '@shared/utils/day-slots/day-slots.utils';
import { ParametersUtils } from '@shared/utils/parameters/parameters.utils';
import { ITimeSlot, TimeSlotsUtils } from '@shared/utils/time-slots/time-slots.utils';

import { SettingsParametersState } from '@store/settings';
import { IOrderTypeCapabilities } from '@config/order-types-capabilities.config';
import { ModalScrollBlockBase } from '@core/base/modalScrollBlock/modal-scroll-block.base';

import { OrdersService } from '@core/services/orders/orders.service';
import { ModalsService } from '@core/services/modals/modals.service';
import { OrderTypeService } from '@core/services/order-type/order-type.service';
import { ShopSearchParametersService } from '@core/services/shop-search-parameters/shop-search-parameters.service';
import { CartStoreSelector } from '@common/selectors/cart.selector';
import { CartStoreDispatcher } from '@common/dispatchers/cart.dispatcher';
import { AuthStoreSelector } from '@common/selectors/authentication.selector';

export type ShopSearchParametersModalInputs = {
  overrideSelectedDistributionMode?: string;
};

export type ShopSearchParametersModalEvents = {
  type: 'updated';
  success: boolean;
};

@Component({
  selector: 'app-shop-search-parameters-modal',
  templateUrl: './shop-search-parameters-modal.component.html',
})
export class ShopSearchParametersModalComponent extends ModalScrollBlockBase implements OnInit {
  static handle = 'shop-search-parameters-modal';

  isMobile$ = this.settingsSelector.selectDeviceIsMobileScreen;

  index$: Observable<number> = this.modalSelector
    .selectModal(ShopSearchParametersModalComponent.handle)
    .pipe(map(el => (el?.index ? el.index : 4)));

  data$: Observable<ShopSearchParametersModalInputs> = this.modalSelector
    .selectModal(ShopSearchParametersModalComponent.handle)
    .pipe(map(el => (el?.data ? el.data : {})));

  @Input() displayAsapForLaterPicker = true;

  distributionModeItems: ISimpleItem[] = BASE_CUSTOM_SWITCH_VALUES;
  selectedOrderTypeCapabilities!: IOrderTypeCapabilities;

  // @fixme default value
  selectedDistributionMode = 'click-and-collect';
  selectedLocation?: string;
  selectedWantedAtType!: DoodWantedAtType;
  selectedWantedAtDate?: Date;
  selectedWantedAtTime?: Date;
  wantedAtDateSlots!: Date[];
  wantedAtTimeSlots?: ITimeSlot[];
  message?: string;
  distributionMode = OrderTypeValues;
  deliveryComment?: string | null;
  instructions$ = this.cartSelector.selectActive.pipe(
    map(cart => cart?.delivery_address?.instructions),
  );

  private newSelectedLocation?: ILocationParameter;
  private intervalMinuteTimeSlot = 15;
  private previousSelectedWantedAtTime?: Date;

  public readonly userIsLoggedIn$ = this.authSelector.selectUserIsLoggedIn;

  constructor(
    private cartSelector: CartStoreSelector,
    private modalSelector: ModalStoreSelector,
    private cartDispatcher: CartStoreDispatcher,
    private readonly modalsService: ModalsService<
      ShopSearchParametersModalInputs,
      ShopSearchParametersModalEvents
    >,
    private readonly ordersService: OrdersService,
    private settingsSelector: SettingsStoreSelector,
    private readonly orderTypeService: OrderTypeService,
    private readonly marketplaceSelector: MarketplaceStoreSelector,
    private readonly shopSearchParametersService: ShopSearchParametersService,
    private readonly authSelector: AuthStoreSelector,
  ) {
    super();
    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);
  }

  ngOnInit(): void {
    const savedParameters = this.shopSearchParametersService.getParameters();
    this.data$.subscribe(data => {
      this.selectedDistributionMode =
        data.overrideSelectedDistributionMode ?? savedParameters.distribution_mode;
    });
    this.selectedLocation = savedParameters.location?.address;
    this.initSelectedWantedAt(savedParameters.wanted_at);
    this.updateSelectedOrderTypeCapabilities();
    this.updateWantedAtDateSlots();
    this.updateWantedAtTimeSlots();
    this.setAllowedMarketplaceDistributionsMode();
    this.updateMessage();
  }

  close(): void {
    this.modalsService.close(ShopSearchParametersModalComponent.handle);
  }

  save(): void {
    const toSave: Partial<SettingsParametersState> = {
      is_distribution_mode_defined: true,
      distribution_mode: this.selectedDistributionMode,
    };

    if (this.newSelectedLocation) {
      toSave.location = this.newSelectedLocation;
      if (this.cartSelector.active) {
        let deliveryAddress = this.ordersService.placeResultToDeliveryAddress(
          this.newSelectedLocation as PlaceResult,
        );

        if (this.deliveryComment && deliveryAddress) {
          deliveryAddress.instructions = this.deliveryComment;
        }

        this.cartDispatcher.updateActive({
          delivery_address: deliveryAddress,
        });
      }
      this.modalsService.emitEvent({ type: 'updated', success: true });
    }

    if (
      this.selectedOrderTypeCapabilities?.preorderingAllowed &&
      this.selectedWantedAtType === DoodWantedAtType.later
    ) {
      toSave.wanted_at = this.selectedWantedAtTime;
    } else {
      toSave.wanted_at = null;
    }

    this.shopSearchParametersService.set(toSave);
    if (this.selectedDistributionMode !== 'delivery') {
      this.modalsService.emitEvent({ type: 'updated', success: true });
    }
    this.close();
  }

  distributionModeSelectionChanged($event: string): void {
    this.selectedDistributionMode = $event;
    this.updateWantedAtDateSlots();
    this.updateSelectedOrderTypeCapabilities();
    this.updateMessage();
  }

  locationChanged(locationValue: Partial<DoodDeliveryAddressModel>): void {
    const location = locationValue as DoodDeliveryAddressModel;
    const locationParameters = LocationUtils.mapDeliveryAddressToLocation(location);
    this.newSelectedLocation = locationParameters;
    this.selectedLocation = locationParameters?.address;
  }

  deliveryCommentChanged(instructions?: string): void {
    this.deliveryComment = instructions;
  }

  private updateSelectedOrderTypeCapabilities(): void {
    this.selectedOrderTypeCapabilities = this.orderTypeService.getCapabilities(
      this.selectedDistributionMode,
    );
  }

  onWantedAtTypeChanged(wantedAtType: DoodWantedAtType): void {
    this.selectedWantedAtType = wantedAtType;
    this.shopSearchParametersService.set({
      wanted_at_type: wantedAtType,
    });
    this.updateWantedAtDateSlots();
  }

  onWantedAtDateChanged(wantedAtDate?: Date): void {
    this.selectedWantedAtDate = wantedAtDate;
    this.previousSelectedWantedAtTime = this.selectedWantedAtTime;
    this.selectedWantedAtTime = undefined;
    this.wantedAtTimeSlots = undefined;
    this.updateWantedAtTimeSlots();
  }

  onWantedAtTimeChanged(wantedAtTime?: Date): void {
    this.selectedWantedAtTime = wantedAtTime;
  }

  private updateWantedAtDateSlots(): void {
    const preOrderingDelays = this.orderTypeService.getPreOrderDelays(
      this.selectedDistributionMode,
    );

    const minDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(preOrderingDelays[DistributionModeKeys.PreorderingMinDelay], 'seconds')
      .startOf('day');
    const maxDate = DateUtils.dayjsInMarketplaceTimezone()
      .add(preOrderingDelays[DistributionModeKeys.PreorderingMaxDelay], 'seconds')
      .startOf('day');

    const today = DateUtils.dayjsInMarketplaceTimezone().startOf('day');
    const minDays = minDate.diff(today, 'days');
    const maxDays = maxDate.diff(today, 'days');
    this.wantedAtDateSlots = DaySlotsUtils.generateDaySlots({
      interval: 1,
      minDays: minDays,
      maxDays: maxDays,
    });

    if (!this.selectedWantedAtDate) {
      this.selectedWantedAtDate = this.wantedAtDateSlots[0];
    }
  }

  private updateWantedAtTimeSlots(): void {
    let hour = 0;
    let minute = 0;

    if (!this.selectedWantedAtDate) {
      this.wantedAtTimeSlots = [];
      return;
    }

    let now = DateUtils.dayjsInMarketplaceTimezone();
    if (DateUtils.dayjsInMarketplaceTimezone(this.selectedWantedAtDate).isToday()) {
      hour = now.hour();
      minute = now.minute();
    }

    const preOrderingDelays = this.orderTypeService.getPreOrderDelays(
      this.selectedDistributionMode,
    );
    this.wantedAtTimeSlots = TimeSlotsUtils.generateTimeSlots({
      day: this.selectedWantedAtDate,
      start: hour,
      startWithMinute: minute,
      end: 24,
      interval: this.intervalMinuteTimeSlot,
      minDate: now.add(preOrderingDelays[DistributionModeKeys.PreorderingMinDelay], 'seconds'),
      maxDate: now.add(preOrderingDelays[DistributionModeKeys.PreorderingMaxDelay], 'seconds'),
    });

    if (!this.wantedAtTimeSlots.length) {
      this.wantedAtTimeSlots = undefined;
      return;
    }

    this.selectedWantedAtTime = new Date(this.wantedAtTimeSlots[0].start.format());

    if (this.previousSelectedWantedAtTime) {
      const selectedSlot = find(
        this.wantedAtTimeSlots,
        slot =>
          slot.start.format('HH:mm:ss') ===
          DateUtils.dayjsInMarketplaceTimezone(this.previousSelectedWantedAtTime).format(
            'HH:mm:ss',
          ),
      );
      if (selectedSlot) {
        this.selectedWantedAtTime = new Date(selectedSlot.start.format());
      }
    }
  }

  private initSelectedWantedAt(wantedAt: Date | null): void {
    if (!wantedAt) {
      this.selectedWantedAtType = DoodWantedAtType.asap;
      this.shopSearchParametersService.set({
        wanted_at_type: DoodWantedAtType.asap,
      });
      return;
    }

    this.selectedWantedAtType = DoodWantedAtType.later;
    this.shopSearchParametersService.set({
      wanted_at_type: DoodWantedAtType.later,
    });
    this.selectedWantedAtDate = wantedAt;
    this.previousSelectedWantedAtTime = wantedAt;
    this.updateWantedAtTimeSlots();
    this.selectedWantedAtTime = wantedAt;
  }

  private updateMessage(): void {
    const currentDistributionMode = this.distributionModeItems?.find(
      distributionMode => distributionMode.value === this.selectedDistributionMode,
    );
    this.message = undefined;
    if (currentDistributionMode) {
      this.message = currentDistributionMode.message;
    }
  }

  private setAllowedMarketplaceDistributionsMode(): void {
    this.marketplaceSelector.selectMarketplaceDistributionModes
      .pipe(map(distributionModes => this.allowedDistributionModesLoaded(distributionModes)))
      .subscribe();
  }

  private allowedDistributionModesLoaded(distributionModes: IDistributionMode[]): void {
    this.distributionModeItems = ParametersUtils.mapDistributionModes(
      distributionModes
        .filter(item => item[DistributionModeKeys.Enabled])
        .sort((a, b) => a[DistributionModeKeys.Priority] - b[DistributionModeKeys.Priority]),
    );

    if (this.distributionModeItems.length === 1) {
      this.selectedDistributionMode = this.distributionModeItems[0].value;
      this.updateSelectedOrderTypeCapabilities();
    }
    if (
      this.distributionModeItems.length === 1 &&
      this.selectedDistributionMode === OrderTypeValues.EatIn
    ) {
      this.close();
    }
  }

  forceType(data: any): any {
    return data as any;
  }
}
