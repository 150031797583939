import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { CartFunnelStoreSelector } from '@common/selectors/cart-funnel.selector';

@Component({
  selector: 'app-kiosk-cart-funnel-form-block',
  templateUrl: './kiosk-cart-funnel-form-block.component.html',
})
export class KioskCartFunnelFormBlockComponent {
  @Input() title!: string;
  @Input() blockId!: string;
  @Input() items: unknown[] = [];

  isActive$: Observable<boolean> = this.cartFunnelSelector.selectActiveStepId.pipe(
    map(id => id === this.blockId),
    delay(0), // Delay to ensure the value is updated after the change detection cycle, I don't know why here it was not updated correctly
  );

  constructor(private cartFunnelSelector: CartFunnelStoreSelector) {}
}
