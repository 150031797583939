import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { DoodModalModel } from '@core/models/modal.model';

import { ModalStoreSelector } from '@common/selectors/modal.selector';
import { ModalStoreDispatcher } from '@common/dispatchers/modal.dispatcher';

@Injectable({
  providedIn: 'root',
})
export class ModalsService<TInputs extends Record<string, unknown> = {}, TEvents = any> {
  private subjectSource = new Subject<TEvents>();
  subject$ = this.subjectSource.asObservable();

  constructor(
    private modalSelector: ModalStoreSelector,
    private modalDispatcher: ModalStoreDispatcher,
  ) {}

  open(handle: string, attribute?: string, data?: { [key: string]: any }): void {
    const allModals = this.modalSelector.modals;
    const lastModal = allModals[0];
    this.modalDispatcher.addModal({
      handle,
      attribute,
      data,
      index: ((lastModal?.index ? lastModal.index / 10 : 0) + 1) * 10,
    });
  }

  close(handle: string): void {
    this.modalDispatcher.removeModal(handle);
  }

  isModalLink(link: string): boolean {
    return link.startsWith('modal://');
  }

  openFromLink(link: string): void {
    const urlWithHttp = link.replace('modal://', 'http://');
    const url = new URL(urlWithHttp);
    const params = new URLSearchParams(url.search);
    const output: { [key: string]: string | null } = {};
    params.forEach((value, key) => {
      output[key] = value;
    });
    const handle = url.host;
    this.open(handle, undefined, output);
  }

  setData(handle: string, data: TInputs): void {
    this.modalDispatcher.updateModal({
      id: handle,
      changes: { data },
    });
  }

  getData(handle: string): Observable<DoodModalModel | undefined> {
    return this.modalSelector.selectModal(handle);
  }

  emitEvent(data: TEvents): void {
    this.subjectSource.next(data);
  }
}
